// Api/config.js
const username = process.env.VUE_APP_USERNAME;
const password = process.env.VUE_APP_PASSWORD;

// Basic Authentication header: `Authorization: Basic base64(username:password)`
const authHeader = `Basic ${btoa(username + ':' + password)}`;

const config = { username, password, authHeader };

export default config;
