<template>
        <header dir="rtl" class="site-header fixed-top">
            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-xl-1 col-lg-1 my-auto d-none d-lg-block">
                        <div class="d-flex">
                            <a href="#" class="sidebar-toggle">
                                <i class="far fa-bars"></i>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 my-auto d-none d-lg-block" style="margin-right: -15px;padding-right: 0;">
                        <div class="d-flex">
                            <div class="logo my-auto">
                                <router-link to="/"  class="custom-logo-link" style="font-size: 24px;">
                                    <!-- <img src="../../assets/images/CR_logo.png" class="custom-logo" alt=""> -->
                                    English Learn
                                </router-link> 
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 my-auto d-none d-xl-block">
                        <form class="ajax-search-form" @submit.prevent="SearchData()">
                            <input type="text" v-model="search" class="keyword" placeholder="Search">
                            <button type="submit"><i class="fa fa-search"></i></button>
                            <ul class="datafetch"></ul>
                        </form>
                    </div>
                    <div class="col-xl-3 col-lg-3 my-auto" >
                        <div class="d-flex">
                            <div class="d-flex">
                                <div class="top-header-action">
                                    <div class="widget-header">
                                        <div class="col-12 my-auto">
                                            <div class="row">
                                                <div class="col-5">
                                                    <label class="toggle-switch pointer"   v-if="showHeader">
                                                    <router-link to="/Unsubscribe" class="viewtube-btn  bordered un_sub"  tabindex="0" savefrom_lm_index="0">الغاء الاشتراك</router-link>
                                                    </label>
                                                    <label class="toggle-switch pointer" v-else>
                                                    <a  @click="gotoDSP()" class="viewtube-btn  bordered" style="display: inline-block;border: 4px solid;" tabindex="0" savefrom_lm_index="0">إشتـراك</a>

                                                    </label>
                                                </div>
                                                <div class="col-6">
                                                    <label class="toggle-switch pointer"  v-if="showHeader">
                                                    </label>
                                                    <label class="toggle-switch pointer" v-else>
                                                    <router-link to="/login" class="viewtube-btn  bordered" style="display: inline-block;border: 4px solid;" tabindex="0" >دخول</router-link>

                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-1 my-auto  d-lg-block">
                        <div class="d-flex">
                            <div class="logo my-auto">
                                <router-link to="/"  class="custom-logo-link"><img style="width: 125px; max-width: 200px;margin-right: -3vw;" src="../../assets/images/logoSvg.svg" class="custom-logo" alt=""></router-link> </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="off-canvas-menu-bar">
            <div class="pl-30 pr-30">
                <div class="row">
                    <div class="col-8 my-auto">
                        <div class="row">
                            <div class="col-5" style="padding-right: 0;padding-left: 0;">
                                <img style="width: 95px;" src="../../assets/images/logoSvg.svg" class="custom-logo" alt="">
                            </div>
                            <div class="col-6" style="padding-right: 0;padding-left: 0;">
                                <label class="toggle-switch pointer" style="width: auto;"   v-if="showHeader">
                                    <router-link to="/Unsubscribe" class="viewtube-btn  bordered" style="display: inline-block;border: 3px solid;" tabindex="0" savefrom_lm_index="0">الغاء الاشتراك</router-link>
                                </label>
                                <label class="toggle-switch pointer" style="width: auto;" v-else>
                                    <a @click="gotoDSP()" style="display: flex; padding: 5px 16px;margin-left: -3vw;border: 3px solid;" class="viewtube-btn  bordered" tabindex="0" savefrom_lm_index="0">إشتـراك</a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 my-auto">
                        <router-link to="/" style="width: auto;" class="custom-logo-link" rel="home" aria-current="page">
                            <!-- <img width="512" height="85" style="margin-left: 0;" src="../../assets/images/CR_logo.png" class="custom-logo" alt=""> -->
                            English Learn
                        </router-link>
                    </div>
                        <div class="col-1 my-auto">
                        <div class="mobile-nav-toggler" style="color: #fff;"><span class="fas fa-bars" ></span></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 " style="margin-top: 10px;padding-right: 0;padding-left: 0;">
                        <div class="col-xl-7 col-lg-7 my-auto  d-xl-block" style="padding-right: 0;padding-left: 0;">
                        <form class="ajax-search-form" @submit.prevent="SearchData()">
                            <input type="text" v-model="search" class="keyword" placeholder="Search">
                            <button type="submit"><i class="fa fa-search"></i></button>
                            <ul class="datafetch"></ul>
                        </form>
                    </div>
                    </div>
                    
                        
                </div>
            </div>
        </div>
        <div class="off-canvas-menu">
            <div class="menu-backdrop"></div>
            <i class="close-btn fa fa-close"></i>
            <nav class="mobile-nav">
                <div class="text-center pt-3 pb-3">
                    <router-link to="/" class="custom-logo-link" rel="home" style="font-size: 24px;">
                        <!-- <img src="../../assets/images/CR_logo.png" class="custom-logo" alt="Sayara"> -->
                        English Learn
                    </router-link>
                </div>
                <ul class="navigation_Mobile" id='myid'>
                    <li class="menu-item current-menu-item"><router-link to="/"><i class="fas fa-home"></i><span>الصفحة الرئيسية </span></router-link></li>
                    <template v-for="publish in categor" :key="publish.id">
                    <li class="menu-item pointer" v-if="publish.type == 1">
                        <a @click="GoToSer(publish)">
                           <i class="fas fa-microphone-alt"></i>
                           <span>{{ publish.name_ar }}</span>
                        </a>
                    </li>
                    <li class="menu-item pointer" v-else><a @click="GoToVideo(publish)"><i class="fab fa-youtube"></i><span>{{ publish.name_ar }}</span></a></li>
                    </template>
                    <template v-if="showHeader">
                        <li class="menu-item pointer"><router-link to="/Unsubscribe"><i class="fas fa-tag"></i><span>إلغاء الاشتراك</span></router-link></li>
                    </template>
                    <template v-else>
                        <li class="menu-item pointer"><router-link to="/Login" ><i class="fas fa-tag"></i><span>تسجيل الدخول</span></router-link></li>
                        <li class="menu-item pointer"><a @click="gotoDSP()" ><i class="fas fa-tag"></i><span>إشتـراك </span></a></li>
                    </template>
                </ul>
            </nav>
        </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { HTTPDSP } from '@/Api/http-dsp';
import { HTTP } from '@/Api/http-common';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import  config  from '@/Api/config';
export default {
   name: 'AppHeader',
     mounted() {
       let externalScript = document.createElement("script");
       externalScript.setAttribute("src", "/static/js/main.js");
       document.head.appendChild(externalScript);
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const search = ref([]);
        const showHeader = ref(true);
        const categor = ref([]);
        const toast = useToast();

        watchEffect(() => {

            if (cookie.isCookieAvailable("msisdn")) {

                try {
                    HTTPDSP.get(`DSPCheckLogin.php?msisdn=${cookie.getCookie("msisdn")}`, {
                        headers: {
                            'Authorization': config.authHeader,
                        },
                    }).then((res) => {
                        if (res.data.status == 1 ) {
                            return;
                        } else {
                            cookie.removeCookie('msisdn');
                        } 
                    });
                } catch (error) {
                    console.log(error);
                }

                showHeader.value = true;
            } else {
                showHeader.value = false;
            }
           
            // if (cookie.isCookieAvailable("msisdn")) {
            //     HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
            //         if (res.data.status == 1 ) {
            //             return;
            //         } else {
            //             cookie.removeCookie('msisdn');
            //         }
            //     });
            //     showHeader.value = true;
            // } else {
            //    showHeader.value = false;
            // //    cookie.removeCookie("msisdn");
            // }
        }); 

        try {
            HTTP.get(`GetAllCourses.php`, {
                headers: {
                   'Authorization': config.authHeader,
               },
            }).then((res) => {
               categor.value = res.data.Courses; 
            });
        } catch (err) {
            console.log(err);
        }

        const GoToSer = (publish) => {
            document.body.removeAttribute("class");
            router.push({ name: "SeriesContent", params: { series_id: publish.id } });
        };

      const GoToVideo = (publish) => {
        document.body.removeAttribute("class");
          router.push({ name: "speaking", params: { cat_id: publish.id } });
          
       
      };
        const SearchData = () => {
            // Define the regex pattern to allow only Arabic and English letters, and spaces
            const validTextRegex = /^[A-Za-z\u0600-\u06FF\s]*$/;

            // Test if the input matches the regex pattern
            if (validTextRegex.test(search.value)) {
                router.push({ name: "Searchs", params: { search_id: search.value } });

            } else {
                // If invalid, remove the non-alphabetic characters and update search query
                search.value = search.value.replace(/[^A-Za-z\u0600-\u06FF\s]/g, '');
                toast.info("خطأ في المدخلات");  // Mark as invalid
            }
        }

        const gotoDSP = () => {
            document.body.removeAttribute("class");
            window.open("https://dsplp.sd.zain.com/?p=2847968194", "_self");
            // router.push({ name: "Subscribe" });
        }
        return { search, showHeader,categor, SearchData, GoToVideo, GoToSer, gotoDSP};
    },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>